export const APP = 'Cuebly';

export const APP_LOGOUT = `${APP}/APP_LOGOUT`;
export const APP_MOUNTED = `${APP}/APP_MOUNTED`;
export const SET_APP_ENABLED = `${APP}/SET_APP_ENABLED`;
export const TOGGLE_DISCONNECTED_SOUND = `${APP}/TOGGLE_DISCONNECTED_SOUND`;
export const TOGGLE_PRESENTATION_MODE = `${APP}/TOGGLE_PRESENTATION_MODE`;
export const APP_LOGIN_ADMIN = `${APP}/APP_LOGIN_ADMIN`;
export const CONNECTION_CHANGED = `${APP}/CONNECTION_CHANGED`;
export const NEW_TOKEN = `${APP}/NEW_TOKEN`;
export const NEW_ADMIN_TOKEN = `${APP}/NEW_ADMIN_TOKEN`;
export const REQUEST_ACTIVATE_MODULE = `${APP}/REQUEST_ACTIVATE_MODULE`;
export const SET_THEME = `${APP}/SET_THEME`;
export const SET_MAP_PROVIDER = `${APP}/SET_MAP_PROVIDER`;
export const USER_CHANGED = `${APP}/USER_CHANGED`;
export const LANGUAGE_CHANGED = `${APP}/LANG_CHANGED`;
export const CONNECTION_TYPE_CHANGED = `${APP}/CONNECTION_TYPE_CHANGED`;
export const SET_IS_ONLINE = `${APP}/SET_IS_ONLINE`;
export const FORCE_RELOAD = `${APP}/FORCE_RELOAD`;
export const SET_ANONYMOUS_MODE = `${APP}/SET_ANONYMOUS_MODE`;
export const SET_SHOW_NEWSLETTER_OPT_IN = `${APP}/SET_SHOW_NEWSLETTER_OPT_IN`;
export const ENQUEUE_SNACKBAR = `${APP}/ENQUEUE_SNACKBAR`;
export const SET_ENQUEUE_SNACKBAR = `${APP}/SET_ENQUEUE_SNACKBAR`;
export const SET_DISMISS_SNACKBAR = `${APP}/SET_DISMISS_SNACKBAR`;
export const SET_ON_MENU_CLICKED = `${APP}/SET_ON_MENU_CLICKED`;
export const SET_SOUND_SETTINGS_OPEN = `${APP}/SET_SOUND_SETTINGS_OPEN`;
export const CHECK_JWT = `${APP}/CHECK_JWT`;
export const KEEP_ALIVE = `${APP}/KEEP_ALIVE`;
export const SET_PALETTE = `${APP}/SET_PALETTE`;
export const RECONNECT_WEBSOCKET = `${APP}/RECONNECT_WEBSOCKET`;
export const SET_ADMIN_MODE = `${APP}/SET_ADMIN_MODE`;
export const GET_DATA = `${APP}/GET_DATA`;
export const RETRY_GET_DATA = `${APP}/RETRY_GET_DATA`;

export const WS_DATA = `${APP}/WS_DATA`;
export const WS_DATA_INIT = `${WS_DATA}/init`;
export const WS_DATA_CHANGELOG = `${WS_DATA}/changeLog`;
export const WS_DATA_MODULES = `${WS_DATA}/modules`;
export const WS_DATA_CREDITS = `${WS_DATA}/credits`;