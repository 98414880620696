import MaterialButton from '@mui/material/Button';

import React from 'react';

import styled from 'styled-components';
import { Colors, contrastColor, darken } from '../../utils/style/colors';
import ButtonProgressBar from './ButtonProgressBar';
import { Shadows } from '../../utils/style/shadows';

class Button extends React.PureComponent {

	render() {
		let {classes, color, loading, active, onRef, onClick, disabled, ...other} = this.props;
		if(disabled) onClick = () => {};

		let colorProp = color;
		let overrideColor = null;
		if(color && color.indexOf('#') >= 0) {
			colorProp = 'primary';
			overrideColor = color;
		}
		colorProp ||= "inherit";

		return (
			<Wrapper {...other} overrideColor={overrideColor} onClick={onClick}>
				<MaterialButton
					ref={r => {
						if(onRef) onRef(r);
					}}
					size="large"
					variant="contained"
					disabled={loading || disabled}
					color={colorProp}
					{...other}
				/>
				{loading && <ButtonProgressBar isVisible={loading}/>}
			</Wrapper>
		);
	}

}

export default Button;

const Wrapper = styled.div`
	display: inline-block;
	position: relative;
	button {
		${props => props.overrideColor && `
			color: ${contrastColor(props.overrideColor)} !important;
			background-color: ${props.overrideColor};
			box-shadow: ${Shadows.button};
			&:hover {
				background-color: ${darken(props.overrideColor, 1.2)};
			}
		`};
		color: ${props => props.color ? contrastColor(props.color) : Colors.gray60};
		${props => props.variant === 'outlined' && props.color === 'white' && `
			color: white;
			border-color: white;
			font-weight: normal;
		`};
	}
	.progressBar {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
`;