import styled from 'styled-components';
import { fontSize } from '../../utils/style/texts';
import { Colors } from '../../utils/style/colors';

export const AppWrapper = styled.div`
	background: ${Colors.background()};
	overflow: hidden;
	margin: 0 auto;
	display: flex;
	align-items: center;
	flex-direction: column;
	min-height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	z-index: 100;
	font-size: ${fontSize.m}pt;
    
    &:after {
        content: '';
        position: fixed;
        background-color: white;
        transition: opacity .3s;
        opacity: ${props => props.isEnabled ? 0 : .5};
        pointer-events: ${props => props.isEnabled ? 'none' : 'all'} !important;
        z-index: 9999;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
	
	.leaflet-div-icon {
		background: transparent;
		border: 0; 
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

export const WhiteLabel = styled.div`
	background: ${Colors.background()};
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: -1;
	background-size: cover;
	transition: all 2s;
`;
export const ConnectionType = styled.div`
    position: fixed;
    color: white;
    background-color: #393E44;
    bottom: 2px;
    right: 2px;
    opacity: .5;
    font-style: italic;
    font-size: 10px;
    z-index: 1000;
    padding: 1px;
`;