import i18n from 'i18next';
import ICU from 'i18next-icu';
import XHR from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import myLanguageDetector from './myLanguageDetector';
import nl from 'dayjs/locale/nl';
import en from 'dayjs/locale/en';
import de from 'dayjs/locale/de';
import es from 'dayjs/locale/es';
import tr from 'dayjs/locale/tr';
import fr from 'dayjs/locale/fr';
import da from 'dayjs/locale/da';
import it from 'dayjs/locale/it';
import cs from 'dayjs/locale/cs';
import * as flags from './app/images/flags';
import * as env from './app/env';

import {csCZ, deDE, enUS, esES, frFR, nlNL, trTR, daDK, itIT} from '@mui/material/locale';
import {
    csCZ as csCZ2,
    daDK as daDK2,
    deDE as deDE2,
    enUS as enUS2,
    esES as esES2,
    frFR as frFR2,
    nlNL as nlNL2,
    trTR as trTR2
} from '@mui/x-data-grid';
import {consoleLog} from "./app/utils/log";

const isEnabled = () => true;

export const languages = [
    {name: 'Česky', lang: 'cs-CZ', dayjs: cs, key: 'cs', flag: flags.cs, isEnabled, mui: csCZ, muiDataGrid: csCZ2},
    {name: 'Dansk', lang: 'da-DK', dayjs: da, key: 'da', flag: flags.da, isEnabled, mui: daDK, muiDataGrid: daDK2},
    {name: 'Deutsch', lang: 'de-DE', dayjs: de, key: 'de', flag: flags.de, isEnabled, mui: deDE, muiDataGrid: deDE2},
    {name: 'English', lang: 'en-GB', dayjs: en, key: 'en', flag: flags.gb, isEnabled, mui: enUS, muiDataGrid: enUS2, isDefault: true},
    {name: 'Español', lang: 'es-ES', dayjs: es, key: 'es', flag: flags.es, isEnabled, mui: esES, muiDataGrid: esES2},
    {name: 'Français', lang: 'fr-FR', dayjs: fr, key: 'fr', flag: flags.fr, isEnabled, mui: frFR, muiDataGrid: frFR2},
    {name: 'Italiano', lang: 'it-IT', dayjs: it, key: 'it', flag: flags.it, isEnabled, mui: itIT, muiDataGrid: itIT},
    {name: 'Nederlands', lang: 'nl-NL', dayjs: nl, key: 'nl', flag: flags.nl, isEnabled, mui: nlNL, muiDataGrid: nlNL2},
    {name: 'Türk', lang: 'tr-TR', dayjs: tr, key: 'tr', flag: flags.tr, isEnabled, mui: trTR, muiDataGrid: trTR2},
];
export const getLocaleInfo = key => languages.find(l => l.key === key || l.lang === key) || getLocaleInfo('en-GB');

export const locales = Object.fromEntries(
    languages.map(l => [l.key, l.lang])
);

export const DEFAULT_LOCALE = locales.en;

const languageDetector = new LanguageDetector();
languageDetector.addDetector(myLanguageDetector);

let muteMissingKey = false;
const missingKeys = {};

i18n
    .use(languageDetector)
    .use(new ICU({
        localeData: languages.map(l => l.dayjs)
    }))
    .use(XHR)
    .use(initReactI18next)
    .init({
        backend: {
            loadPath: env.apiBase() + '/pub/lang/{{lng}}',
        },
        load: 'languageOnly',
        debug: false,
        ns: ['translations'],
        defaultNS: 'translations',
        missingKeyHandler: async (lang, ns, key) => {
            if (key && key !== 'false') {
                if (!env.isLive()) {
                    if (missingKeys[key]) return;
                    missingKeys[key] = true;
                    consoleLog(`missing keys: ${Object.keys(missingKeys)} for lang ${lang}`);
                    if (muteMissingKey) return;
                    muteMissingKey = true;
                    window.alert(`missing key: ${key} for lang ${lang}`);
                    setTimeout(() => muteMissingKey = false, 5000);
                }
            }
        },
        saveMissing: true,
        keySeparator: false,
        detection: {
            order: ['myLanguageDetector', 'navigator']
        },
        nonExplicitWhitelist: true,
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true
        },
    });

export default i18n;