import Hidden from '@mui/material/Hidden';

import React from 'react';
import * as styles from './style';
import {BrowserRouter} from 'react-router-dom';

import PageContainer from '../PageContainer';
import * as Sentry from '@sentry/browser';
import {Text} from '../../utils/style/texts';
import {Flex} from '../../components/Container/Flex';
import dayjs from 'dayjs';
import {SecurityIcon} from '../../utils/icons';
import AlertDialog from '../AlertDialog';
import DisconnectedSnackbar from '../../components/Snackbars/DisconnectedSnackbar';
import {UserAPI} from '../../utils/API/UserAPI';
import ActivateModuleDialog from '../../components/Dialogs/ActivateModule';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import muiTheme from '../../utils/style/muiTheme';
import {getActivePage, getLangFromLocale} from '../../utils/functions';
import {Responsive} from '../../utils/style/responsive';
import PresentationPageDialog from '../PresentationPageDialog';
import ConnectDeviceDialog from '../ConnectDeviceDialog';
import AlarmSound from '../../components/AlarmSound';
import SoundSettingsDialog from '../SoundSettings';
import TrialExpiredDialog from '../TrialExpiredDialog';
import {GlobalStyle} from '../../global-styles';
import {browserVersion, isSupportedBrowser} from '../../index';
import {min_to_ms} from '../../utils/time';
import {getStore} from '../../configureStore';
import AssistanceRequestDialog from '../AssistanceRequestPage/AssistanceRequestDialog';
import {ConnectedDots} from '../../components/ConnectedDots';
import {getWhitelabel} from '../../env';
import loadable from '@loadable/component';
import {createStructuredSelector} from 'reselect';
import {compose} from 'redux';
import {withTranslation} from 'react-i18next';
import {withSnackbar} from 'notistack';
import {connect} from 'react-redux';
import {showAlert} from '../AlertDialog/actions';
import {
    selectActivateModule, selectConnectionType,
    selectCurrentUser,
    selectIsAppEnabled,
    selectTheme
} from '../../redux/selectors/app.selectors';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {
    appMounted,
    checkJWT, connectionTypeChanged,
    getData,
    keepAlive, languageChanged,
    logout,
    requestActivateModule,
    retryGetData, setDismissSnackbar,
    setEnqueueSnackbar, setIsOnline,
} from '../../redux/actions/app.actions';
import Navigator from '../../Navigator';
import {createBrowserHistory} from 'history';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import '@mui/lab';
import TestAlarmLinkDialog from "../TestAlarmLinkDialog";
import DeviceForm from "../DeviceForm";

const PlatformPage = loadable(() => import('../PlatformPage'));

const UnauthorizedRoutes = loadable(() => import('./UnauthorizedRoutes'));
const AdminRoutes = loadable(() => import('./AdminRoutes'));
const AccountManagerRoutes = loadable(() => import('./AccountManagerRoutes'));
const EndUserRoutes = loadable(() => import('./EndUserRoutes'));
const ARCRoutes = loadable(() => import('./ARCRoutes'));
const PresentationRoutes = loadable(() => import('./PresentationRoutes'));

const history = createBrowserHistory();

const recaptchaSiteKey = '6LdJwmchAAAAABHCsybjQQ48JCliIOs_uo1XtL7K';

const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

class App extends React.PureComponent<any> {

    static keepAliveInterval;

    onlineHandler(isOnline) {this.props.setIsOnline(isOnline); }
    focusHandler() {
        if (Responsive.isMobile() || this.props.user?.isPresentation) {
            this.props.getData();
        } else {
            this.props.retryGetData();
        }
        this.props.checkJWT();
    }
    constructor(props) {
        super();
        window.addEventListener('focus', () => this.focusHandler());
        window.addEventListener("online", () => this.onlineHandler(true));
        window.addEventListener("offline", () => this.onlineHandler(false));
        connection?.addEventListener('change', () => this.props.connectionTypeChanged());
        if (App.keepAliveInterval) clearInterval(App.keepAliveInterval);
        App.keepAliveInterval = setInterval(() => {
            if (localStorage.getItem('rememberMe') !== 'yes') return;
            getStore().dispatch(keepAlive());
        }, min_to_ms(15));
    }

    componentDidMount() {
        const {t} = this.props;
        this.props.setEnqueueSnackbar(this.props.enqueueSnackbar);
        this.props.setDismissSnackbar(this.props.closeSnackbar);
        this.props.appMounted();

        const isSupportedVersion = !!''.replaceAll && !!global.globalThis;

        if (!isSupportedBrowser || !isSupportedVersion) {
            this.props.showAlert(
                <Flex>
                    <div style={{opacity: .5, width: 100}}>
                        <SecurityIcon fontSize="large" style={{transform: 'scale(2)', transformOrigin: '5px -10px'}}/>
                    </div>
                    <Text>
                        {t('browserWarning.1')}.<br/>
                        {t('browserWarning.3')}.<br/>
                        <br/>
                        {!isSupportedBrowser && (
                            <>
                                <div dangerouslySetInnerHTML={{__html: t('browserWarning.4')}}/>
                                .<br/>
                            </>
                        )}
                        {!isSupportedVersion && t('browserWarning.5.version', {version: 'v' + browserVersion})}
                    </Text>
                </Flex>,
            );
        }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error});
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    render() {
        if(this.valid === false) return null;
        const {user, activateModule, closeModuleActivation, enqueueSnackbar, t, i18n, languageChanged, isEnabled} = this.props;
        const query = new URLSearchParams(window.location.search);
        const newLang = query.get("sl");
        if(newLang) {
            query.delete("sl");
            languageChanged(newLang, () => window.location.replace('?' + query.toString()));
            this.valid = false;
            return null;
        }
        if(getActivePage() === 'cache') {
            localStorage.removeItem('user');
            localStorage.removeItem('adminUser');
            window.location.href = '/';
            return null;
        }
        
        const lang = getLangFromLocale(i18n.language);
        if (dayjs.locale() !== lang) {
            setTimeout(() => dayjs.locale(lang));
        }

        return (
            <StyledEngineProvider injectFirst>
                <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
                    <ThemeProvider theme={muiTheme(lang)}>
                        <GlobalStyle/>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            locale={lang}
                            adapterLocale={lang}
                        >
                            <styles.AppWrapper isEnabled={isEnabled !== false}>
                                <styles.WhiteLabel/>
                                <styles.ConnectionType>{this.props.connectionType}</styles.ConnectionType>

                                {getActivePage() === 'device-form' ? (
                                    <DeviceForm />
                                ) : (
                                    <BrowserRouter history={history}>
                                        <Navigator>
                                            {user?.lastLogin ? this.renderPlatform() : this.renderLogin()}
                                        </Navigator>
                                    </BrowserRouter>    
                                )}

                                <AlertDialog/>
                                <PresentationPageDialog/>
                                <ConnectDeviceDialog/>
                                <SoundSettingsDialog/>
                                <TrialExpiredDialog/>
                                <AssistanceRequestDialog/>
                                <DisconnectedSnackbar/>
                                <TestAlarmLinkDialog/>

                                <ActivateModuleDialog open={!!activateModule} onClose={comment => {
                                    if (comment !== false) {
                                        (new UserAPI()).sendModuleActivationRequest(activateModule, comment).then();
                                        enqueueSnackbar(t('moduleRequested'));
                                    }
                                    closeModuleActivation();
                                }}/>
                            </styles.AppWrapper>
                        </LocalizationProvider>
                    </ThemeProvider>
                </GoogleReCaptchaProvider>
            </StyledEngineProvider>
        );
    }

    renderPlatform = () => {
        const {user} = this.props;
        const activePage = getActivePage();

        if (user.isPresentation || activePage === 'presentation') {
            return (
                <PageContainer>
                    <PresentationRoutes/>
                </PageContainer>
            );
        }

        return (
            <PageContainer>
                <AlarmSound/>
                {user.isAlarmCenter ? <ARCRoutes/> : (
                    <PlatformPage>
                        {user.isUser && <EndUserRoutes/>}
                        {user.isAccountManager && <AccountManagerRoutes/>}
                        {user.isSuperAdmin && <AdminRoutes/>}
                    </PlatformPage>
                )}
            </PageContainer>
        );
    };

    renderLogin = () => (
        <>
            {!!getWhitelabel().showDots && (
                <Hidden mdDown>
                    <ConnectedDots/>
                </Hidden>
            )}
            <UnauthorizedRoutes/>
        </>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        appMounted: () => dispatch(appMounted(dispatch)),
        closeModuleActivation: () => dispatch(requestActivateModule(null)),
        showAlert: (content, title, options) => dispatch(showAlert(content, title, options)),
        logout: () => dispatch(logout()),
        setEnqueueSnackbar: f => dispatch(setEnqueueSnackbar(f)),
        setDismissSnackbar: f => dispatch(setDismissSnackbar(f)),
        getData: () => dispatch(getData()),
        retryGetData: () => dispatch(retryGetData()),
        checkJWT: () => dispatch(checkJWT()),
        setIsOnline: o => dispatch(setIsOnline(o)),
        languageChanged: (l,c) => dispatch(languageChanged(l,c)),
        connectionTypeChanged: () => dispatch(connectionTypeChanged())
    };
}

const mapStateToProps = createStructuredSelector({
    theme: selectTheme(),
    user: selectCurrentUser(),
    activateModule: selectActivateModule(),
    isEnabled: selectIsAppEnabled(),
    connectionType: selectConnectionType()
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
    withTranslation(),
    withSnackbar,
    withConnect,
)(App);
