import {
  APP_LOGIN_ADMIN,
  APP_LOGOUT,
  APP_MOUNTED,
  CHECK_JWT,
  CONNECTION_CHANGED, CONNECTION_TYPE_CHANGED,
  ENQUEUE_SNACKBAR,
  FORCE_RELOAD,
  GET_DATA,
  KEEP_ALIVE,
  LANGUAGE_CHANGED,
  NEW_ADMIN_TOKEN,
  NEW_TOKEN,
  RECONNECT_WEBSOCKET,
  REQUEST_ACTIVATE_MODULE,
  RETRY_GET_DATA,
  SET_ADMIN_MODE,
  SET_ANONYMOUS_MODE, SET_APP_ENABLED, SET_DISMISS_SNACKBAR,
  SET_ENQUEUE_SNACKBAR, SET_IS_ONLINE,
  SET_MAP_PROVIDER,
  SET_ON_MENU_CLICKED,
  SET_SHOW_NEWSLETTER_OPT_IN,
  SET_SOUND_SETTINGS_OPEN, TOGGLE_DISCONNECTED_SOUND, TOGGLE_PRESENTATION_MODE,
  USER_CHANGED,
  WS_DATA,
  WS_DATA_INIT,
} from '../constants/app.constants';

export const appMounted = dispatch => ({ type: APP_MOUNTED, dispatch });
export const logout = isExpired => ({ type: APP_LOGOUT, isExpired: !!isExpired });
export const loginAdmin = () => ({ type: APP_LOGIN_ADMIN });
export const forceReload = (doLogout) => ({ type: FORCE_RELOAD, doLogout });
export const newToken = token => ({type: NEW_TOKEN, token});
export const newAdminToken = token => ({type: NEW_ADMIN_TOKEN, token});
export const connectionChanged = isConnected => ({type: CONNECTION_CHANGED,isConnected});
export const dataReceived = (type, data) => ({ type: `${WS_DATA}/${type}`, data });
export const requestActivateModule = (modId) => ({ type: REQUEST_ACTIVATE_MODULE, modId });
export const setMapProvider = provider => ({type: SET_MAP_PROVIDER, provider});
export const languageChanged = (language, callback) => ({type: LANGUAGE_CHANGED, language, callback});
export const connectionTypeChanged = () => ({type: CONNECTION_TYPE_CHANGED});
export const setIsOnline = isOnline => ({type: SET_IS_ONLINE, isOnline});
export const setAnonymousMode = isAnonymous => ({type: SET_ANONYMOUS_MODE, isAnonymous});
export const setShowNewsLetterOptIn = show => ({type: SET_SHOW_NEWSLETTER_OPT_IN, show});
export const enqueueSnackbar = (input, attrs) => ({type: ENQUEUE_SNACKBAR, input, attrs});
export const setEnqueueSnackbar = enqueueSnackbar => ({type: SET_ENQUEUE_SNACKBAR, enqueueSnackbar});
export const setDismissSnackbar = dismissSnackbar => ({type: SET_DISMISS_SNACKBAR, dismissSnackbar});
export const setOnMenuClicked = onClick => ({type: SET_ON_MENU_CLICKED, onClick});
export const getData = () => ({ type: GET_DATA });
export const retryGetData = () => ({ type: RETRY_GET_DATA });
export const userChanged = user => ({ type: USER_CHANGED, user });
export const setSoundSettingsOpen = isOpen => ({ type: SET_SOUND_SETTINGS_OPEN, isOpen });
export const checkJWT = () => ({ type: CHECK_JWT });
export const keepAlive = () => ({ type: KEEP_ALIVE });
export const reconnectWebsocket = (delayMs, force) => ({ type: RECONNECT_WEBSOCKET, delayMs, force });
export const setAdminMode = isEnabled => ({ type: SET_ADMIN_MODE, isEnabled });
export const websocketInitialized = () => ({ type: WS_DATA_INIT });
export const setAppEnabled = isEnabled => ({type: SET_APP_ENABLED, isEnabled});
export const toggleDisconnectedSound = (isEnabled) => ({type: TOGGLE_DISCONNECTED_SOUND, isEnabled});
export const togglePresentationMode = () => ({type: TOGGLE_PRESENTATION_MODE});
