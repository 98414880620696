export const nl = {x: -128, y: -99};
export const gb = {x: -80, y: -44};
export const de = {x: -32, y: -33};
export const fr = {x: -48, y: -44};
export const it = {x: -32, y: -66};
export const tr = {x: -0, y: -143};
export const es = {x: -192, y: -33};
export const fi = {x: -224, y: -33};
export const da = {x: -64, y: -33};
export const cs = {x: -16, y: -33};
export const all = require('./flags.png');